let scrollPos = window.scrollY;
let isThrottled = false;

const setHeaderbackground = () => {
  if (isThrottled) {
    return;
  }
  isThrottled = true;
  setTimeout(function() {
    isThrottled = false;
  }, 100);

  if (window.scrollY > 100) {
    document.querySelector("body").classList.add("scroll");
  } else {
    document.querySelector("body").classList.remove("scroll");
  }
};

document.addEventListener("scroll", () => {
  setHeaderbackground();
});
