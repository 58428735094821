const navToggle = document.querySelector(".nav__toggle");

navToggle.addEventListener("click", (toggler) => {
  activateNav(toggler);
});

document.querySelectorAll(".menu-item").forEach((item) => {
  if (item.classList.contains("menu-item-has-children")) {
    const passiveLink = item.querySelector("a");
    passiveLink.removeAttribute("href");
    passiveLink.addEventListener("click", (link) => {
      activateNav(link, "sublink");
    });
  }
});

const activateNav = (e, location) => {
  e.target.classList.toggle("active");

  if (location !== "sublink") {
    document.querySelector(".header").classList.toggle("nav-open");
  } else {
    document.querySelector("#primary-menu").classList.toggle("sub-nav-open");
    let navBack = document.createElement("div");
    navBack.classList.add("nav_back");
    navBack.innerHTML = "Zurück";
    e.target.nextElementSibling.prepend(navBack);

    navBack.addEventListener("click", (backItem) => {
      e.target.classList.toggle("active");
      document.querySelector("#primary-menu").classList.toggle("sub-nav-open");
      backItem.target.remove();
    });
  }
};
