//Machine Gallery

document.addEventListener("DOMContentLoaded", () => {
  const imageList = document.querySelectorAll(".machine__gallery-item");
  imageList.forEach((item) => {
    item.addEventListener("click", (e) => {
      imageList.forEach((item) => {
        item.classList.remove("machine__gallery-item--active");
      });
      item.classList.add("machine__gallery-item--active");

      let activeImage = e.target.attributes["data-image-id"].value;

      const mainContainer = document.querySelector(".machine__gallery-main");

      mainContainer
        .querySelectorAll(".machine__gallery-image")
        .forEach((item) => {
          item.classList.remove("machine__gallery-image--active");
        });

      let activeMainImage = mainContainer.querySelector(
        "[data-image-id='" + activeImage + "']"
      );
      activeMainImage.classList.add("machine__gallery-image--active");
    });
  });
});

//Machine Form

document.addEventListener(
  "DOMContentLoaded",
  function () {
    const machineName = document.querySelector("[data-machine]");
    if (machineName) {
      document.getElementById("machinename").value =
        machineName.attributes["data-machine"].value;
    }
  },
  false
);
