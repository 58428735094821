// import Vue from 'vue';

// Vue.component('test-component')



import Vue from 'vue';
import testComponent from './testComponent.vue';


Vue.component('test-component', testComponent);


new Vue({
    el: '#app'
})